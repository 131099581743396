var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($) {
	'use strict';

	function OfferPopover() {
		this.options = {
			container: '.offer-popover'
		};
	}

	OfferPopover.prototype.init = function(offerSettings) {
		this.$container = $(this.options.container);
		this.$close = $('.offer-close', this.$container);
		this.$trigger = $('.trigger', this.$container);
		this.$right = $('.offer-right', this.$container);
		this.$textCollapsed = $('.text-collapsed', this.$container);
		this.$textExpanded = $('.text-expanded', this.$container);
		
		var offer = this.getScheduledOffer(offerSettings);
		if(offer != null) {
			this.populateOffer(offer);
			this.addEvents();
			this.open()
		}
		
	}

	OfferPopover.prototype.addEvents = function() {
		var self = this;
		this.$close.on('click', function() {
			self.close();
		})
		this.$trigger.on('click', function() {
			self.expand();
		});
		window.addEventListener('scroll', function() {
			self.collapse()
		});
	};

	OfferPopover.prototype.getScheduledOffer = function(offerSettings) {
		var now = new Date();
		var stringCurrentDateTime =
			now.getFullYear() +
			'-' + (now.getMonth()+1).toString().padStart(2, 0) +
			'-' + now.getDate().toString().padStart(2, 0) +
			'T' + now.getHours().toString().padStart(2, 0) +
			':' + now.getMinutes().toString().padStart(2, 0) +
			':' + now.getSeconds().toString().padStart(2, 0);

		var activeOffers = offerSettings.offers.filter(function(item) {
			var startValid = item.dateStart <= stringCurrentDateTime;
			var endValid = item.dateEnd >= stringCurrentDateTime;
			var enabled = item.pages.indexOf(parseInt(offerSettings.currentPage, 10)) >= 0;
			return startValid && endValid && enabled;
		});

		if(activeOffers.length > 0) {
			return activeOffers[0];
		}
		return null;
	};
	
	OfferPopover.prototype.populateOffer = function(offer) {
		$('.primary-image', this.$container).append('<img src="'+offer.primaryImage+'" alt="" />');
		
		if(offer.secondaryImage !== '') {
			$('.secondary-image', this.$container).show();
			$('.secondary-image', this.$container).append('<img src="'+offer.secondaryImage+'" alt="" />');	
		}

		if(offer.link !== null) {
			$('.link', this.$container).show();
			$('.link', this.$container).append('<a href="'+offer.link.url+'">'+offer.link.name+'</a>');
		}

		if(offer.bottomTextClosed !== '') {
			if(offer.bottomTextOpen === '') {
				offer.bottomTextOpen = offer.bottomTextClosed;
			}
			$('.bottom-text', this.$container).show();
			$('.bottom-text .text-collapsed', this.$container).show();
			$('.bottom-text .text-collapsed', this.$container).append(offer.bottomTextClosed);
			$('.bottom-text .text-expanded', this.$container).hide();
			$('.bottom-text .text-expanded', this.$container).append(offer.bottomTextOpen);
		}
	};

	OfferPopover.prototype.open = function() {
		if(!this.$container.hasClass('show')) {
			this.$container.addClass('show');	
		}
	};

	OfferPopover.prototype.close = function() {
		this.$container.removeClass('show');
	};

	OfferPopover.prototype.expand = function() {
		this.$container.addClass('expanded')
		
		setTimeout(function() {
			$('.bottom-text .text-collapsed', self.$container).hide();
			$('.bottom-text .text-expanded', self.$container).show();
		}, 300);
	};

	OfferPopover.prototype.collapse = function() {
		var self = this;
		this.$container.removeClass('expanded')

		setTimeout(function() {
			$('.bottom-text .text-collapsed', self.$container).show();
			$('.bottom-text .text-expanded', self.$container).hide();
		}, 300);
	};

	THF.Interface.OfferPopover = new OfferPopover();
})(jQuery);