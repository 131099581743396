String.prototype.capitalize = function () {
    return this.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
};
moment.locale("en-US");

$(function () {

    /**********************************
     COMPONENT + MODULE INIT CALLS
     **********************************/
    THF.Interface.PrimaryNavigation();
    
    // Footer accordion
    THF.Interface.Accordion({
        animate: false,
        selectors: {
            parentElem: '#master-footer .primary-links',
            accordionTrigger: '.list-header',
            accordionTarget: 'ul'
        }
    });
       
    // Globally Used
    THF.Interface.PageUtility();
    THF.Interface.Callback();
    
    window.THF.Instance = window.THF.Instance || {};
    new THF.Interface.SearchDesktop();

    //Init Picture fill
    picturefill();

    // Pass the options to the dropdown and hack to populate the first option with 
    // The same value.
    // $("select").selectpicker({ noneSelectedText: "Please select" });
    $("select").selectpicker({
        noneSelectedText: "Please select",
        template: {
            caret: '<span class="caret">'+
                   '<svg class="icon icon-closed" viewBox="0 0 22.52 11.99"><use xlink:href="' + window.THF.SiteSettings.svgSprite + '#ui-up"></use></svg>' +
                   '<svg class="icon icon-open" viewBox="0 0 22.52 11.99"><use xlink:href="' + window.THF.SiteSettings.svgSprite + '#ui-down"></use></svg>' +
                   '</span>'
        }
    });
    $("li[data-original-index='0'] .text").html("Please select");

    $.fn.tooltipster('setDefaults', {
        animation: 'fade',
        trigger: 'hover',
        maxWidth: 270,
        delay: 150,

        functionBefore: function (origin, continueTooltip) {
            continueTooltip();
            origin.addClass('active');
        },

        functionAfter: function (origin, continueTooltip) {
            origin.removeClass('active');
        }
    });

    $('.tooltip').tooltipster();

    THF.Interface.RatingsFeedback();

    window.THF.Utility.StateManager.run();

});