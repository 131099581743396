var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($, jQuery) {
	'use strict';

	THF.Interface.PrimaryNavigation = function( options ) {

		var defaults = {

			selectors: {
				//Top level site wrapper - needed for mobile nav
				masterContainer: '#master-container',

				//Module Parent and desktop element selectors
				parentElem: '#primary-nav',
				primaryNav: '.top-level',
				primaryNavItem: '> li',
				subNav: '.secondary-nav',

				//Mobile selectors
				mobileNav: '#mobile-nav',
				closeBtn: '.close-nav-btn'				
			},

			useHybridMmenu: false
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var masterContainer = $(options.selectors.masterContainer);
		var parent = $(options.selectors.parentElem);
		var primaryNav = $parentElem(options.selectors.primaryNav);
		var primaryNavItem = primaryNav.find(options.selectors.primaryNavItem);
		var subNav = $parentElem(options.selectors.subNav);
		var mobileNav = $(options.selectors.mobileNav);
		var closeBtn = $(options.selectors.closeBtn);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Interface Events
		**********************************/
		function showSubNav(primaryNavTrigger, targetSubnavElem){
			$(targetSubnavElem, primaryNavTrigger).stop().show();
		}

		function hideSubNav(primaryNavTrigger, targetSubnavElem){
			$(targetSubnavElem, primaryNavTrigger).stop().hide();
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents(){

		}

		//Primary Nav specific events
		function bindPrimaryNavEvents(targetElem) {

			//var primaryNavTrigger = $(targetElem);
			//var targetSubNav = primaryNavTrigger.next(options.selectors.subNav);

			var primaryNavTrigger = $(targetElem);
			var primaryNavElem = primaryNavTrigger.find('a:first-child');
			var targetSubNav = primaryNavTrigger.find(options.selectors.subNav);


			primaryNavTrigger.hoverIntent({
				over: function () {
					primaryNavElem.addClass('active');
					showSubNav(primaryNavTrigger, targetSubNav);
				},

				out: function () {
					primaryNavElem.removeClass('active');
					hideSubNav(primaryNavTrigger, targetSubNav);
				},
				timeout: 400
			});
		}


		/**********************************
		// Mobile nav functions
		**********************************/
		function initMobileNavUsingMmenu(){

			//Clone the primary nav into the mobile nav
			var navClone = parent.html();
			mobileNav.append(navClone);

			//Init the mobile nav plugin
			mobileNav.mmenu({

				offCanvas: {
					position: 'right',
					zposition: 'front',
					blockUI: true

				},

				navbar: {
					add:true,
					title: 'Menu',
					titleLink: 'none'
					//Possible values: "parent", "anchor" or "none".
				}
			});
			var API = mobileNav.data( "mmenu" );
			closeBtn.click(function() {
				API.close();
			});

			API.bind("openPanel", function($panel) {
                $('.mm-prev', $panel).html('<svg class="icon" viewBox="0 0 11.98 22.52"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/svg/sprite.svg#ui-left"></use></svg>');
			});

            // "bind","init","update","setSelected","getInstance","openPanel","closePanel","closeAllPanels"


			/**
			*
			Hack - sorry
			Top level links don't have an actual page, so on click
			menu closes, rather than open the next subnav panel. Only arrows
			have the click event bound, so need to bind custom click events
			*
			**/
			var toplvlMobileLink = mobileNav.find('.primary-link');

			toplvlMobileLink.click(function() {

				var targetBtn = $(this).prev('a');
				targetBtn.trigger('click');
				return false;
			});



		}


		function initMobileNavUsingHybrid(){
			
			//Clone the primary nav into the mobile nav
			var navClone = parent.html();
			mobileNav.append(navClone);

			THF.Interface.HybridMMenu();

		}		



		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init			
			if(mobileNav.length){
				if(options.useHybridMmenu)
					initMobileNavUsingHybrid();										
				else
					initMobileNavUsingMmenu();
			}
				

			//Bind primary nav rollover events
			primaryNavItem.each(function() {
				bindPrimaryNavEvents(this);
			});

		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);
