(function ($, window, undefined) {
    'use strict';

    window.THF = window.THF || {};
    window.THF.Interface = window.THF.Interface || {};
    window.THF.Interface.Callback = function (options) {

        const INVALID_PHONE_ERROR_CODE = 2;
        const INVALID_PHONE_ERROR_MESSAGE = "Invalid phone number.";        

        var model = {
            'name': '',
            'phone': '',
            'is_member': '',
            'member_number': '',
            'date_selection': 'asap',
            'day': '',
            'preferred_timeslot': '',
            'reason': ''
        };
        var validationRules = {
            'name': [],
            'phone': [
                'required',
                'requiredGenericPhone'
            ],
            'is_member': [
                'required'
            ],
            'member_number': [],
            'date_selection': [
                'required'
            ],
            'day': [],
            'preferred_timeslot': [
                'required'
            ],
            'reason': []
        };

        var defaults = {
            selectors: {
                modalForm: '.callback',
                modalSuccess: '.callback-success',
                modalTryAgain: '.callback-error-try-again',
                modalError: '.callback-error',
                form: '.callback-form',
                loadingPanel: '.loading-panel',
                hiddenGroups: {
                    isMember: '.group-hideshow-is-member',
                    isNotMember: '.group-hideshow-is-not-member',
                    dateTypeSelection: '.group-hideshow-datetype-selection',
                    dateTimeSelection: '.group-hideshow-datetime-selection',
                    dateSelection: '.group-hideshow-date-selection',
                    timeSelection: '.group-hideshow-time-selection'
                },
                fieldPhone: 'input[name="phone"]',
                fieldDateSelection: 'input[name="date_selection"]',
                fieldDay: 'input[name="day"]',
                fieldTimeslot: 'select[name="preferred_timeslot"]',
                fieldReason: 'select[name="reason"]',
                callbackOpen: '.callback-open',
                btnCancel: '.btn-cancel',
                btnSubmit: '.btn-submit'
            }
        };
        options = $.extend(true, {}, defaults, options);

        // Records the attempts for the form submissions, so that we can show custom error screens.
        var attempt = 0;


        /**********************************
         // Declare variables
         **********************************/
        var $modalForm = $(options.selectors.modalForm);
        var $modalTryAgain = $(options.selectors.modalTryAgain);
        var $modalError = $(options.selectors.modalError);
        var $modalSuccess = $(options.selectors.modalSuccess);
        var $btnSubmit = $containerForm(options.selectors.btnSubmit);
        var $form = $containerForm(options.selectors.form);
        var $fields = $('input, select, textarea', $form);
        var $fieldPhone = $containerForm(options.selectors.fieldPhone);
        var $fieldDateSelection = $containerForm(options.selectors.fieldDateSelection);
        var $fieldDay = $containerForm(options.selectors.fieldDay);
        var $fieldTimeslot = $containerForm(options.selectors.fieldTimeslot);
        var $fieldReason = $containerForm(options.selectors.fieldReason);
        var $hiddenGroupIsMember = $containerForm(options.selectors.hiddenGroups.isMember);
        var $hiddenGroupIsNotMember = $containerForm(options.selectors.hiddenGroups.isNotMember);
        var $hiddenGroupDateTypeSelection = $containerForm(options.selectors.hiddenGroups.dateTypeSelection);
        var $hiddenGroupDateTimeSelection = $containerForm(options.selectors.hiddenGroups.dateTimeSelection);
        var $hiddenGroupDateSelection = $containerForm(options.selectors.hiddenGroups.dateSelection);
        var $hiddenGroupTimeSelection = $containerForm(options.selectors.hiddenGroups.timeSelection);
        var $loadingPanel = $containerForm(options.selectors.loadingPanel);

        function debugLog(param1, param2){
            if(window.THFLogging === true)
                console.log(param1, param2);
        }

        function $containerForm(selector) {
            return $(selector, $modalForm);
        }

        function bindEvents() {
            $fields.change(eventFieldUpdate);
            $btnSubmit.click(eventSubmit);

            $fieldTimeslot.selectpicker({
                noneSelectedText: "Please select",
                template: {
                    caret: '<span class="caret">' +
                        '<svg class="icon icon-closed"><use xlink:href="' + window.THF.SiteSettings.svgSprite + '#ui-up"></use></svg>' +
                        '<svg class="icon icon-open"><use xlink:href="' + window.THF.SiteSettings.svgSprite + '#ui-down"></use></svg>' +
                        '</span>'
                }
            });

            $(options.selectors.callbackOpen).click(eventOpenForm);

            $('.btn-cancel', $modalForm).click(eventCloseForm);
            $('.btn-cancel', $modalSuccess).click(eventCloseSuccess);
            $('.btn-cancel', $modalError).click(eventCloseError);
            $('.btn-cancel', $modalTryAgain).click(eventCloseTryAgain);
            $('.btn-homepage', $modalSuccess).click(eventGotoHomepage);
            $('.btn-homepage', $modalError).click(eventGotoHomepage);
            $('.btn-try-again', $modalTryAgain).click(eventTryAgain);

            $containerForm('.modalSuccess').click(function () {
                openModalSuccess('ABCDEF', 123);
            });
            $containerForm('.modalError').click(function () {
                openModalError();
            });

            // btn-homepage
        }

        function setupFieldMasks() {
            $fieldPhone.mask('0000000000');
            $fieldDay.mask('00/00/0000');
        }

        function getInvalidDates() {
            var invalidDates = $modalForm.attr('data-invalid-dates');
            return JSON.parse(invalidDates);
        }

        function getTimeslotDateStart() {
            var timeslotTimeStart = $modalForm.attr('data-time-start');
            return timeslotTimeStart;
        }

        function getTimeslotDateEnd() {
            var timeslotTimeEnd = $modalForm.attr('data-time-end');
            return timeslotTimeEnd;
        }

        function eventFieldUpdate() {
            populateModel();
            hideShowGroups();
            setValidationRules();
            setOptions();
        }

        function eventSubmit() {
            populateModel();

            var isValid = validate();
            if (isValid) {
                submitCallback();
            }
        }

        function eventGotoHomepage(e) {
            e.preventDefault();
            e.stopPropagation();
            window.location = '/';
        }

        function eventTryAgain(e) {
            e.preventDefault();
            e.stopPropagation();
            closeModalTryAgain();
            openModalForm();
        }

        function eventCloseTryAgain(e) {
            e.preventDefault();
            e.stopPropagation();
            closeModalTryAgain();
        }

        function eventOpenForm(e) {
            e.preventDefault();
            e.stopPropagation();
            openModalForm();
        }

        function eventCloseForm(e) {
            e.preventDefault();
            e.stopPropagation();
            closeModalForm();
        }

        function eventCloseSuccess(e) {
            e.preventDefault();
            e.stopPropagation();
            closeModalSuccess();
        }

        function eventCloseError(e) {
            e.preventDefault();
            e.stopPropagation();
            closeModalError();
        }

        function setValidationRules() {
            if (model.date_selection === 'choose') {
                validationRules.day = [
                    'required',
                    'thfDateCallback'
                ];

                validationRules.preferred_timeslot = [
                    'required'
                ];
            }
            else {
                validationRules.day = [];
                validationRules.preferred_timeslot = ['required'];
            }
        }

        /**
         * Shows and hides the validation error messages and returns the validation response.
         *
         * @returns {boolean}
         */
        function validate() {
            var validationFocused = false;
            var result = true;
            for (var field in validationRules) {
                var $validMessage = $('.field-validation-error', $containerForm('*[name="' + field + '"]').parents('.field-wrap'));
                if (validationRules.hasOwnProperty(field)) {
                    var rules = validationRules[field];

                    for (var i = 0; i < rules.length; i++) {
                        var rule = rules[i];
                        var ruleResult = THF.Utility.Validation.validateRule(rule, model[field], undefined, {
                            invalidDates: getInvalidDates()
                        });

                        if (ruleResult.result === false) {
                            result = false;
                            if (!validationFocused) {
                                $containerForm('*[name="' + field + '"]').focus();
                                validationFocused = true;
                            }

                            $validMessage.html(ruleResult.message).show();
                        }
                        else {
                            $validMessage.hide().html('');
                        }
                    }
                }
            }
            return result;
        }

        /**
         * Submits the callback to the callback API.
         */
        function submitCallback() {
            $loadingPanel.show();
            var callbackData = {
                name: model.name,
                member: (model.is_member === 'Y'),
                memberNumber: '',
                date: '',
                phoneNumber: model.phone,
                message: model.reason
            };

            if (callbackData.member) {
                callbackData.memberNumber = model.member_number;
            }

            if (callbackData.name === '') {
                callbackData.name = 'Name unknown';
            }


            callbackData.date = moment.tz(model.day + ' ' + model.preferred_timeslot, 'DD/MM/YYYY HH:mm', 'Australia/Sydney')
                .toISOString();
            
            THF.Api.Callback.Initiate(
                callbackData.name,
                callbackData.phoneNumber,
                callbackData.member,
                callbackData.memberNumber,
                callbackData.date,
                callbackData.message,
                function (response) {
                    $loadingPanel.hide();
                    var formInstance = document.querySelector('form.callback-form');
                    if (response.result) {
                        window.VWO = window.VWO || [];
                        window.VWO.push(['nls.formAnalysis.markSuccess', formInstance, 1]);
                        openModalSuccess(response.content.callbackRequestID, response.content.estimatedWaitTime);
                        clearForm();
                    }
                    else if(response.errorCode === INVALID_PHONE_ERROR_CODE){
                        window.VWO = window.VWO || [];
                        window.VWO.push(['nls.formAnalysis.markSuccess', formInstance, 0]);
                        displayInvalidPhoneError();
                    }
                    else {
                        window.VWO = window.VWO || [];
                        window.VWO.push(['nls.formAnalysis.markSuccess', formInstance, 0]);
                        openModalError();
                    }
                }
            );
        }

        function displayInvalidPhoneError(){

            var $elem = $("#id-callback-phone");

            //Get parent
            var $parent = $elem.closest(".input");
            var $errorElem = $parent.siblings(".field-validation-error");

            if($errorElem.length === 0)
            {
                $parent.after(`<div class="field-validation-error" style="">${INVALID_PHONE_ERROR_MESSAGE}</div>`)
            }
            else{
                $errorElem.text(INVALID_PHONE_ERROR_MESSAGE);
                $errorElem.show();
            }
        }

        function clearForm() {
            attempt = 0;
            $('input[type="text"],textarea', $form).each(function () {
                $(this).val('');
            });

            $('input[type="radio"]', $form).each(function () {
                $(this).prop('checked', false);
            });

            $('input[type="checkbox"]', $form).each(function () {
                $(this).prop('checked', false);
            });

            $('select', $form).each(function () {
                $(this).val('').selectpicker('refresh');
            });
            $($fieldDateSelection.first()).prop('checked', true);
            model.is_member = '';
            populateModel();
            setOptions();
            hideShowGroups()
        }
        
        function getCurrentDateTime() {
            // return moment('2024-03-26 00:40:00').tz('Australia/Sydney');
            // return moment('2024-03-26 08:49:00').tz('Australia/Sydney');
            // return moment('2024-03-26 08:59:00').tz('Australia/Sydney');
            // return moment('2024-03-26 09:00:00').tz('Australia/Sydney');
            // return moment('2024-03-26 09:50:00').tz('Australia/Sydney');
            // return moment('2024-03-26 10:00:00').tz('Australia/Sydney');
            // return moment('2024-03-26 10:20:00').tz('Australia/Sydney');
            // return moment('2024-03-26 10:30:00').tz('Australia/Sydney');
            // return moment('2024-03-26 11:00:00').tz('Australia/Sydney');
            // return moment('2024-03-26 11:50:00').tz('Australia/Sydney');
            // return moment('2024-03-26 14:40:00').tz('Australia/Sydney');
            // return moment('2024-03-26 23:59:00').tz('Australia/Sydney');

            debugLog("getCurrentDateTime(): User locale time", moment().format());
            debugLog("getCurrentDateTime(): Sydney timezone", moment().tz('Australia/Sydney').format());

            // Converts current time to Australis/Sydney timezone
            return moment().tz('Australia/Sydney');
        }
        
        /*
            @australianDateStringFormat = Date string using 'YYYY-MM-DD' format
        */
        function isInvalidDate(australianDateStringFormat) {
            var date = moment.tz(australianDateStringFormat, "YYYY-MM-DD", 'Australia/Sydney');
            debugLog("isInvalidDate(): date parsed to Sydney Timezone", date.format());
            
            var now = getCurrentDateTime();
            var invalidDates = getInvalidDates();

            debugLog("isInvalidDate(): now.format()", now.format());
            debugLog("isInvalidDate(): - now.isSame(date, 'day')", now.isSame(date, 'day'));

            // Same day and after 2:30 PM AEST
            if (now.isSame(date, 'day') && ((now.hour() >= 14 && now.minute() >= 30) || now.hour() >= 15)) {
                debugLog("isInvalidDate(): Same day and after 2:30 PM AEST: Result", "INVALID");
                return true;
            }
            
            // Weekends
            debugLog("isInvalidDate(): Weekends: date.day() should be 0 or 6 to be invalid", date.day());
            if (date.day() === 0 || date.day() === 6) {
                debugLog("isInvalidDate(): Weekends: Result", "INVALID");
                return true;
            }
            // Invalid dates from Umbraco
            else {
                for (var i = 0; i < invalidDates.length; i++) {
                    var invalidDate = invalidDates[i].Date.split('T')[0];                    
                    if (invalidDate === date.format('YYYY-MM-DD')) {
                        debugLog("isInvalidDate(): Invalid dates from Umbraco: Result", "INVALID");                     
                        return true;
                    }
                }
            }
            
            return false;
        }

        function setupCalendar() {           
            var dateOptions = {
                changeYear: true,
                changeMonth: true,
                dayNamesMin: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
                dateFormat: 'dd/mm/yy',
                showOn: "button",
                buttonImage: "/static/img/common/backgrounds/trans.gif",
                buttonText: "Select date",
                buttonImageOnly: true,                
                minDate: '+1d',
                maxDate: '+1w',
                beforeShowDay: function (date) {        
                    
                    // Format date to YYYY-MM-DD
                    var dateFormatted = `${date.getFullYear()}-${padLeadingZero(date.getMonth() + 1)}-${padLeadingZero(date.getDate())}`;
                    
                    debugLog("--setupCalendar(): beforeShowDay (RAW): ", date);
                    debugLog("--setupCalendar(): beforeShowDay: ", dateFormatted);
                    
                    var isValid = !isInvalidDate(dateFormatted);
                    
                    return [
                        isValid
                    ];
                }
            };

            $containerForm('.datepicker input').datepicker(dateOptions);
            window.THF.Utility.StateManager.addOnEnter('sm', function () {
                $containerForm('.datepicker input').attr('readonly', true);
            });
            window.THF.Utility.StateManager.addOnLeave('sm', function () {
                $containerForm('.datepicker input').attr('readonly', false);
            });
        }

        function padLeadingZero(number){
            if(number < 10)
                return '0' + number;

            return number;
        }

        function setTimezoneText(){
            var current = getCurrentDateTime();

            var tzText = ' AEST';
            if (current.isDST()) {
                tzText = ' AEDT';
            }

            var elements = document.getElementsByClassName("timezone-text");

            for(let element of elements){
                element.innerHTML = tzText;
            }
        }

        function getTimeslots() {
            var date = moment.tz(model.day, 'DD/MM/YYYY', 'Australia/Sydney');
            debugLog("getTimeslots(): date.format()", date.format());
            if (model.day === '' || isInvalidDate(date.format('YYYY-MM-DD'))) {
                return [{
                    value: '',
                    label: 'Please select a valid day.'
                }];
            }

            var current = getCurrentDateTime();          

            var tzText = ' AEST';
            if (date.isDST()) {
                tzText = ' AEDT';
            }

            var startTime = moment.tz(getTimeslotDateStart(), 'HH:mm', 'Australia/Sydney');
            var endTime = moment.tz(getTimeslotDateEnd(), 'HH:mm', 'Australia/Sydney');
            
            var timeDiff = parseInt(moment.duration(endTime.diff(startTime)).asHours(), 10) + 1;
            
            if (model.day === current.format('DD/MM/YYYY')) {
                var currentHour = current.hour();
                var currentMinute = 0;
                if (current.minute() < 30) {
                    currentMinute = 30;
                }
                else {
                    currentHour = (current.hour() + 1);
                    currentMinute = 0;
                }

                currentHour = prefixZero(currentHour);
                currentMinute = prefixZero(currentMinute);

                var startHour = currentHour;
                var startMinute = currentMinute;
                
                if(currentHour + ':' + currentMinute < startTime.format('HH:mm')) {
                    startHour = startTime.format('HH');
                    startMinute = startTime.format('mm');
                }
                
                startTime = moment(prefixZero(startHour) + ':' + prefixZero(startMinute), 'HH:mm');
            }

            var timeslots = [];

            for (var i = 0; i < timeDiff; i++) {
                var hour = startTime.hours() + i;
                var time = prefixZero(hour) + ':' + prefixZero(0);
                var time30 = prefixZero(hour) + ':' + prefixZero(30);
                var time60 = prefixZero(hour + 1) + ':' + prefixZero(0)
                if (startTime.format("HH:mm") <= time && time30 <= endTime.format("HH:mm")) {
                    timeslots.push({
                        value: time,
                        label: time + ' - ' + time30 + ' ' + tzText
                    });
                }

                if (time30 < endTime.format("HH:mm") && time60 <= endTime.format("HH:mm")) {
                    timeslots.push({
                        value: time30,
                        label: time30 + ' - ' + time60 + ' ' + tzText
                    });
                }

            }

            return timeslots;
        }

        function prefixZero(number) {
            number = parseInt(number, 10);
            return (number < 10) ? '0' + number : number.toString();
        }

        function setOptions() {
            var timeslots = getTimeslots();

            $fieldTimeslot.html('');

            if(timeslots.length > 0) {
                if(model.preferred_timeslot === '') {
                    model.preferred_timeslot = timeslots[0].value;
                    $fieldTimeslot.value = model.preferred_timeslot;
                }    
            }
            
            
            for (var i = 0; i < timeslots.length; i++) {
                var timeslot = timeslots[i];
                var option = $('<option>').val(timeslot.value).text(timeslot.label);
                if (model.preferred_timeslot === timeslot.value) {
                    option.attr('selected', true);
                    option.prop('selected', true);
                    
                    $fieldTimeslot.val(timeslot.value);
                    $fieldTimeslot.selectpicker('val', timeslot.value);
                    
                }
                $fieldTimeslot.append(option);
            }

            $fieldTimeslot.selectpicker('refresh');
        }

        function hideShowGroups() {
            var now = getCurrentDateTime();
            debugLog("hideShowGroup(): now.format()", now.format());
            if (isInvalidDate(now.format('YYYY-MM-DD'))) {
                debugLog("hideShowGroup(): IsInvalid: YES");
                $hiddenGroupDateTypeSelection.hide();
                model.date_selection = 'choose';
                $fieldDateSelection.val('choose');
            }
            else {
                debugLog("hideShowGroup(): IsInvalid: NO");
                $hiddenGroupDateTypeSelection.show();
            }

            if (model.is_member === 'Y') {
                $hiddenGroupIsMember.show();
            }
            else {
                $hiddenGroupIsMember.hide();
            }

            if (model.is_member === 'N') {
                $hiddenGroupIsNotMember.show();
            }
            else {
                $hiddenGroupIsNotMember.hide();
            }

            if (model.date_selection === 'choose') {
                $hiddenGroupDateTimeSelection.show();
                $hiddenGroupDateSelection.show();
                $hiddenGroupTimeSelection.show();
            }
            else {
                $hiddenGroupDateTimeSelection.show();
                $hiddenGroupDateSelection.hide();
                $hiddenGroupTimeSelection.show();
            }
        }

        function populateModel() {
            var data = $form.serializeArray();
            for (var i = 0; i < data.length; i++) {
                model[data[i].name] = data[i].value;
            }

            if(model.date_selection === 'asap') {
                model.day = getCurrentDateTime().format('DD/MM/YYYY');
                getTimeslots();
            }
        }

        function closeModalForm() {
            $('html,body').removeClass('ngdialog-open');
            $modalForm.hide();
        }

        function openModalForm() {
            $('html,body').addClass('ngdialog-open');
            $modalForm.show();
            $fieldReason.selectpicker('refresh');
        }

        function closeModalSuccess() {
            $('html,body').removeClass('ngdialog-open');
            $modalSuccess.hide();
        }

        function hideShowRefNumberOrSuccessMsg(refNumber){
            var $refContainer = $('.reference-number-container', $modalSuccess);
            var $successContainer = $('.success-message-container', $modalSuccess);

            if(refNumber){
                $refContainer.show();
                $successContainer.hide();
            }
            else{
                $refContainer.hide();
                $successContainer.show();
            }
        }

        function openModalSuccess(referenceNumber, waitTime) {
            $('.reference-number', $modalSuccess).html(referenceNumber);
            hideShowRefNumberOrSuccessMsg(referenceNumber);

            $('.est-wait', $modalSuccess).html(waitTime);
            closeModalForm();
            $('html,body').addClass('ngdialog-open');
            $modalSuccess.show();
        }

        function closeModalError() {
            $('html,body').removeClass('ngdialog-open');
            $modalError.hide();
            clearForm();
        }

        function closeModalTryAgain() {
            $('html,body').removeClass('ngdialog-open');
            $modalTryAgain.hide();
        }

        function openModalError() {
            closeModalForm();
            $('html,body').addClass('ngdialog-open');

            if (attempt > 0) {
                $modalError.show();
            }
            else {
                $modalTryAgain.show();
                attempt++;
            }

        }

        function __construct() {
            if ($modalForm.length > 0) {
                bindEvents();
                eventFieldUpdate();
                setupCalendar();
                setupFieldMasks();
                setTimezoneText();
            }

            if(THF.Utility.Location.queryParam('show') === 'callback') {
                openModalForm();
            }
        }

        __construct();

        return {};
    };


})(jQuery, window);