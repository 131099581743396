THF.Utility.Validation.addRule('thfEmail', function (value, message, options) {
    var result = false;
    message = message || 'Please enter a valid email address.';

    
    var PROD_REGEX =/^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z_])*@(([0-9a-zA-Z])+([-\w]*[0-9a-zA-Z])*\.)+[a-zA-Z]{2,9})$/; 
    var TEST_REGEX = /^([0-9a-zA-Z]([-.\w+]*[0-9a-zA-Z_])*@(([0-9a-zA-Z])+([-\w]*[0-9a-zA-Z])*\.)+[a-zA-Z]{2,9})$/;
        
    function getRegex(){
        switch(window.THF.SiteSettings.env){
            case "local":
            case "release":
            case "staging":
            case "uat":
            case "psup":
                return TEST_REGEX;
        }

        return PROD_REGEX;
    }

    var REGEX = getRegex();

    if( REGEX.test(value) ) {
        result = true;
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});