(function (window, document, undefined) {
    'use strict';

    window.DSTAPI = window.DSTAPI || {};
    window.DSTAPI.Engine = window.DSTAPI.Engine || {};
    window.DSTAPI.Engine.UniversalAnalyticsGTM = function (oEngineOptions) {
        var activeTracker = '';

        var bInitialised = false;

        // Only call the live code if we are not using a test implementor.
        if( typeof oEngineOptions.testImplementor !== 'function' ) {
            // Load the analytics library
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga_GTM');
        }

        /**
         * Used to override GA for testing purposes.
         */
        function implGA() {
            if (typeof oEngineOptions.testImplementor === 'function') {
                oEngineOptions.testImplementor.apply(null, arguments);
            }
            else {
                ga_GTM.apply(null, arguments);
            }
        }

        /**
         * Allows for the setting of a unique client id for tracking users instead of sessions.
         *
         * @param {object} oOptions Options to be passed in for setting various things up.
         */
        function setClientId(oOptions) {
            if (typeof oOptions.clientId === 'undefined') {
                throw {error: "Invalid Client Id", args: oOptions};
            }

            oEngineOptions.clientId = oOptions.clientId;

            implGA('create', oEngineOptions.account, {
                'clientId': oEngineOptions.clientId,
                'name': 'UserTracking'
            });

            activeTracker = 'UserTracking.';
        }

        /**
         * Initialises the UA instance with the relevant options being passed through.
         * @param oOptions
         */
        function initialise(oOptions) {
            if( typeof oOptions.account !== 'undefined' ) {
                bInitialised = true;

                if (typeof oOptions.initialiseOptions !== 'undefined') {
                    // init with options
                    implGA('create', oOptions.account, oOptions.initialiseOptions);
                } else {
                    //base init
                    implGA('create', oOptions.account);
                }

                if (typeof oOptions.autoLinkerDomains !== 'undefined') {
                    implGA('require', 'linker');
                    implGA('linker:autoLink', oOptions.autoLinkerDomains, false, true);
                }

                if (typeof oOptions.remarketing !== 'undefined' && oOptions.remarketing) {
                    implGA('require', 'displayfeatures');
                }
            }
            else {
                DSTAPI.Core.error('UA Code is not being passed through.');
            }
        }

        /**
         * Allows for sending either page views or virtual page views through to
         * google analytics.
         *
         * Example oOptions
         * {
         *     url: '/virtual-page',     // Generally used for specifying a virtual page view, leaving this empty will track the current page.
         *     hitback: function() {}    // Used to callback when analytics has finished calling, this allows you to trigger a window.location when tracking is done.
         * }
         * @param {object} oOptions See Above
         */
        function page(oOptions) {
            if( !bInitialised ) {
                return;
            }
            var oPageOptions = {};
            if( typeof oOptions !== 'undefined' ) {
                if (typeof oOptions.url !== 'undefined') {
                    oPageOptions.page = oOptions.url;
                }

                if (typeof oOptions.hitCallback === 'function') {
                    oPageOptions.hitCallback = oOptions.hitback;
                }
            }
            implGA(activeTracker + 'send', 'pageview', oPageOptions);
        }

        /**
         * Allows for sending an event through to google analytics.
         *
         * Example oOptions
         * {
         *     category: 'Some Category',
         *     action: 'Cliiick',
         *     label: '> 9000!',
         *     value: 0
         * }
         *
         * @param {object} oOptions See Above
         */
        function event(oOptions) {
            if( !bInitialised ) {
                return;
            }
            if (typeof oOptions.category === 'undefined') {
                oOptions.category = null;
            }
            if (typeof oOptions.action === 'undefined') {
                oOptions.action = null;
            }
            if (typeof oOptions.label === 'undefined') {
                oOptions.label = null;
            }
            if (typeof oOptions.value === 'undefined') {
                oOptions.value = null;
            }
            else {
                if (typeof oOptions.value !== 'number') {
                    oOptions.value = 0;
                }
                else {
                    oOptions.value = parseInt(oOptions.value, 10);
                }
            }
            implGA(activeTracker + 'send', 'event', oOptions.category, oOptions.action, oOptions.label, oOptions.value);
        }

        /**
         * Allows for passing a transaction through to the implGA.
         *
         * Example oOptions
         * {
         *     orderid: '',
         *     affiliation: '',
         *     total: '',
         *     tax: '',
         *     shipping: '',
         *     city: '',
         *     state: '',
         *     country: '',
         *     items: [
         *         {
         *             sku: '',
         *             product_name: '',
         *             category: '',
         *             unit_price: '10.20',
         *             quanitity: '1'
         *         },
         *         {
         *             sku: '',
         *             product_name: '',
         *             category: '',
         *             unit_price: '10.20',
         *             quantity: '1'
         *         }
         *     ]
         * );
         *
         * @param {object} oOptions See Above
         */
        function transaction(oOptions) {
            if( !bInitialised ) {
                DSTAPI.Core.error('Cannot trigger a transaction as the analytics library did not initialise.');
                return;
            }
            if(
                typeof oOptions.orderid !== 'undefined'
                && typeof oOptions.orderid !== 'undefined'
                && typeof oOptions.affiliation !== 'undefined'
                && typeof oOptions.total !== 'undefined'
                && typeof oOptions.shipping !== 'undefined'
                && typeof oOptions.tax !== 'undefined'
            ) {
                // Push a transaction to google
                implGA('ecommerce:addTransaction', {
                    'id': oOptions.orderid,
                    'affiliation': oOptions.affiliation,
                    'revenue': oOptions.total,
                    'shipping': oOptions.shipping,
                    'tax': oOptions.tax
                });

                // Checks if the items in defined and if it is defined it loops through
                // and assigns the items to the order id in google analytics.
                if (typeof oOptions.items !== 'undefined') {
                    var iItemLength = oOptions.items.length;
                    var item = {};
                    for (var i = 0; i < iItemLength; i++) {
                        item = oOptions.items[i];
                        // Push an item to google
                        implGA(activeTracker + 'ecommerce:addItem', {
                            'id': oOptions.orderid,
                            'name': item.product_name,
                            'sku': item.sku,
                            'category': item.category,
                            'price': item.unit_price,
                            'quantity': item.quantity
                        });
                    }
                }
                implGA(activeTracker + 'send', 'ecommerce');
            }
            else {
                DSTAPI.Core.error('Missing required transaction fields.');
                return;
            }
        }

        initialise(oEngineOptions);

        return {
            'setClientId': setClientId,
            'page': page,
            'event': event,
            'transaction': transaction
        }
    };

})(window, document);