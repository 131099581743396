THF.Utility.Validation.addRule('requiredGenericPhone', function (value, message, options) {
    var result = false;
    message = message || 'Please enter a phone number.';

    if( value.length && value.length > 0 ) {
        result = true;
    }

    if( result ) {
        var REGEX = /^[0-9]+$/;
        if( value !== '' && REGEX.test(value) ) {
            result = true;
        }
        else {
            result = false;
            message = 'Phone number can only consist of numbers.';
        }
    }

    if( result ) {
        if( value.length !== 10 ) {
            result = false;
            message = 'Phone number must be exactly 10 digits.';
        }
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});