var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($, jQuery) {
	'use strict';

	THF.Interface.Accordion = function( options ) {

		var defaults = {
			animate: true,
			animateSpeed: 150,
			selectors: {
				parentElem: '.accordion',
				accordionTrigger: '.accordion-trigger',
				accordionTarget: '.accordion-target'

			},
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parentElem = $(options.selectors.parentElem);
		var accordionTrigger = $parentElem(options.selectors.accordionTrigger);
		var accordionTarget = $parentElem(options.selectors.accordionTarget);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		function openAccordion(targetElem){

			if(options.animate ==+ true){
				targetElem.velocity("slideDown", {
					duration: options.animateSpeed,

					complete: function(){
						targetElem.addClass('active');
					}
				});

			} else {

				targetElem.addClass('active');
			}
		}

		function closeAccordion(targetElem){

			if(options.animate === true){
				targetElem.velocity("slideUp", {
					duration: options.animateSpeed,

					complete: function(){
						targetElem.removeClass('active');
					}
				});

			} else {

				targetElem.removeClass('active');
			}
		}

		function bindEvents(){
			accordionTrigger.click(function(e) {

				var targetAccordion = $(this).next(options.selectors.accordionTarget);

				if(targetAccordion.hasClass('active')){

					$(this).removeClass('active');
					closeAccordion(targetAccordion);

				} else {

					$(this).addClass('active');
					openAccordion(targetAccordion);
				}

				e.preventDefault();
			});
		}
		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			// Init
			if (parentElem.length > 0){
				bindEvents();
			}
		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
		};
	};


})(jQuery, jQuery);
