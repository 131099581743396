THF.Utility.Validation.addRule('requiredPhoneNumber', function (value, message, options) {
    var overallResult = false;
    var overallMessage = '';
    var result = false;
    var details = [];
    message = message || 'Please enter a phone number.';

    var typesInUse = [];

    if( value.length && value.length > 0 ) {
        result = true;
    }

    if( result ) {
        result = false;
        var number;
        for( var i = 0; i < value.length; i++ ) {
            number = value[i];
            typesInUse.push(number.type);
            if( number.type !== '' ) {
                result = true;
            }
            else {
                result = false;
                message = 'Please enter a valid phone number type from the options provided.';
            }

            if( result ) {
                var REGEX = /^[0-9]+$/;
                if( number.value !== '' && REGEX.test(number.value) ) {
                    result = true;
                }
                else {
                    result = false;
                    message = 'Phone numbers can only consist of numbers.';
                }
            }

            if( result ) {
                if( number.value.length > 10 ) {
                    result = false;
                    message = 'Phone numbers cannot be longer than 10 digits.';
                }
            }

            if( result ) {
                if( number.type === 'mobile' ) {
                    if(number.value.substring(0, 2) !== '04' && number.value.substring(0, 2) !== '05') {
                        result = false;
                        message = 'Please enter a valid mobile number (starting with 04, numbers only, and must be 10 digits).';
                    }
                }
                else {
                    if(
                        number.value.substring(0, 2) !== '02'
                        && number.value.substring(0, 2) !== '03'
                        && number.value.substring(0, 2) !== '07'
                        && number.value.substring(0, 2) !== '08'
                    ) {
                        result = false;
                        var phoneType = 'work';
                        if(number.type == 'home') {
                            phoneType = 'home';
                        }
                        message = 'Please enter a valid '+ phoneType +' phone number (starting with area code, numbers only, and must be 10 digits)';
                    }
                }
            }
            
            details.push({
                'type': number.type,
                'result': result,
                'message': message
            });
        }
    }

    
    if(details.length > 0) {
        var invalidResults = details.filter(function(item) {
            return !item.result;
        });
        overallResult = true;
        overallMessage = '';
        
        if(invalidResults.length > 0) {
            overallResult = false;
            overallMessage = invalidResults.map(function(item) {
                return item.message;
            }).join('<br>');
        }
    }
    
     
    
    if( overallResult ) {
        var skipCommPreference = false;
        if(!skipCommPreference && typeof options === 'undefined') {
            skipCommPreference = true;
        }

        if(!skipCommPreference && typeof options.globalModel === 'undefined') {
            skipCommPreference = true;
        }
        
        if(!skipCommPreference && typeof options.globalModel.contact === 'undefined') {
            skipCommPreference = true;
        }
        
        if(!skipCommPreference && typeof options.globalModel.contact.communicationPreference === 'undefined') {
            skipCommPreference = true;
        }

        if(!skipCommPreference && options.globalModel.contact.communicationPreference == 'sms' ) {
            overallResult = ( $.inArray('mobile', typesInUse) >= 0 );
            if( !overallResult ) {
                overallMessage = 'Mobile must be selected when you have a communication preference of "Electronically via Member Services (with SMS notification)" selected.';
            }
        }
    }

    if( overallResult ) {
        overallMessage = '';
    }

    return {
        'result': overallResult,
        'message': overallMessage,
        'details': details
    };
});