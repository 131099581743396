var THF = THF || {};
THF.Interface = THF.Interface || {};
(function($, jQuery) {
    'use strict';

    THF.Interface.RatingsFeedback = function( options ) {

        var defaults = {
            apiConfig: {
                endPoint: '/api/v1/ratings/',
                commentEndPoint: '/api/v1/ratings/comment'
            },

            selectors: {
                //Module Parent
                parentElem: 'div[data-rating-feedback]',
            },

            text: 'Was this helpful?',
            errorMsg: 'Oops, try again please',
            apiUrl: 'https://reqres.in/api/users/2',
            commentForm: {
                introText: 'Please let us know how we can improve.',
                buttonLabel: 'Submit feedback',
                thankYouMessage: 'Thank you for your feedback.'
            },

            feedbackOptions: [
                {
                    "iconRef": 'ui-sentiment-happy',
                    "wasHelpful": 1
                },
                {
                    "iconRef": 'ui-sentiment-sad',
                    "wasHelpful": 0
                }
            ]
        };

        options = $.extend(true, {}, defaults, options );
        
        // Used to save an optional comment if the feedback is not helpful
        var ratingId = 0;

        /**********************************
         // Declare DOM elements
         **********************************/
        var parentElem = $(options.selectors.parentElem);

        /**********************************
         // Helper function to return a jQuery object, which is a child of 'parentElem'
         **********************************/
        function $parentElem( selector ) {
            return $(options.selectors.parentElem+' '+selector);
        }


        /**********************************
         // Inject elems and 'build' the UI
         **********************************/
        function injectTxtAndControls(){
            var questionText =
                `<div class="rating-top">
                    <span class="txt">${options.text}</span>
                    ${getControls()}
                </div>`;
            parentElem.append(questionText);
        }


        function injectErrorMsg(){
            var errorMsg =
                '<div class="error-msg">'+
                    '<svg class="icon">' +
                        '<use xlink:href="' + window.THF.SiteSettings.svgSprite + '#ui-notification"></use>' +
                    '</svg>'+
                    '<span class="txt">'+options.errorMsg+'</span>';
                '</div>';
            parentElem.append(errorMsg);
        }

        function getControls(){
            var btn = "";
            options.feedbackOptions.forEach( function(obj){

                btn +=
                    '<button class="btn-ratings" data-helpful='+obj.wasHelpful+'>' +
                        '<svg class="icon">' +
                            '<use xlink:href="' + window.THF.SiteSettings.svgSprite + '#'+obj.iconRef+'"></use>' +
                        '</svg>'+
                    '</button>';                
            });

            return btn;
        }

        function injectCommentForm(){
            var commentForm = `<div class="rating-bottom">
                                    <span>${options.commentForm.introText}</span>
                                    <textarea></textarea>
                                    <button class="btn-save-comment" type="button">${options.commentForm.buttonLabel}</button>
                                </div>`;

            parentElem.append(commentForm);
        }

        /**********************************
         // State Management
         **********************************/
        function setProcessingState(bool){
            if(bool === true){
                parentElem.addClass('processing');
            } else {
                parentElem.removeClass('processing');
            }
        }

        function setErrorState(bool){

            if(bool === true){
                parentElem.addClass('error');
            } else {
                parentElem.removeClass('error');
            }
        }

        function setCompletedState(targetBtn){
            parentElem.addClass('completed');
            targetBtn.addClass('completed');
        }

        /**********************************
         // API methods
         **********************************/

        function postFeedback(pageId, wasHelpful, comment, targetBtn){
            var apiUrl = options.apiConfig.endPoint;

            var fd = new FormData();
            fd.append('pageId', pageId);
            fd.append('rating', wasHelpful);
            fd.append('comment', comment);

            $.ajax({
                type: 'POST',
                crossDomain: true,
                url: apiUrl,
                data: fd,
                processData: false,
                contentType: false,
                cache: false,

            })
                .done(function(response) {

                    if (response.status === true){
                        setProcessingState(false);

                        if(targetBtn){
                            setCompletedState(targetBtn);
                        }

                        // Track WasHelpful event                        
                        var helpfulResult = "Negative";
                        if (wasHelpful === '1') {
                            helpfulResult = "Positive";
                        }
                        // Not helpful, show the thank you message
                        else
                        {
                            $parentElem(".rating-bottom").html(`<span>${options.commentForm.thankYouMessage}</span>`);
                            setErrorState(false);
                        }

                        //console.log(helpfulResult);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'ratingSubmitted',
                            'rating': helpfulResult
                        });
                    }
                })

                .fail(function(jqXHR, textStatus, errorThrown) {
                    setErrorState(true);
                    console.log(errorThrown);
                })
        }

        function postComment(comment){
            var apiUrl = options.apiConfig.commentEndPoint;

            var fd = new FormData();
            fd.append('ratingId', ratingId);
            fd.append('comment', comment);

            $.ajax({
                type: 'POST',
                crossDomain: true,
                url: apiUrl,
                data: fd,
                processData: false,
                contentType: false,
                cache: false,

            })
                .done(function(response) {

                    if (response.status === true){
                        $parentElem(".rating-bottom").html(`<span>${options.commentForm.thankYouMessage}</span>`);
                        setErrorState(false);
                    }
                    else{
                        setErrorState(true);
                    }
                })

                .fail(function(jqXHR, textStatus, errorThrown) {
                    setErrorState(true);
                    console.log(errorThrown);
                })
        }

        /**********************************
         // Event Collections
         **********************************/
        function submitFeedback(pageId, wasHelpful, comment, targetBtn){
            setProcessingState(true);
            postFeedback(pageId, wasHelpful, comment, targetBtn);
        }


        /**********************************
         // Event binding
         **********************************/
        function bindEvents(){
            var $btn = $parentElem('.btn-ratings');
            $btn.click(function(e) {

                var wasHelpful = e.currentTarget.getAttribute('data-helpful');               
                var targetBtn = $(e.currentTarget);

                // If not helpful, show the comment form and fill the rating id variable
                if(wasHelpful === '0')
                {
                    $parentElem(".rating-bottom").css("display", "flex");
                    setCompletedState(targetBtn);
                }
                else
                {
                    var pageId = parentElem.attr('data-page-id');                   
                    submitFeedback(pageId, wasHelpful, "", targetBtn);
                }
                
            });

            $parentElem(".btn-save-comment").click(function(){

                var textarea = $parentElem("textarea");
                var comment = textarea.val();

                if(comment === '' || comment === null){
                    textarea.addClass("error")                   
                    return;
                }

                var wasHelpful = '0';               
                var pageId = parentElem.attr('data-page-id');                

                submitFeedback(pageId, wasHelpful, comment, null);

            });

            $parentElem("textarea").keyup(function(){
                $(this).removeClass("error");
            });
        }

        /**********************************
         // Simple constructor
         **********************************/
        function __construct() {

            // Init
            if (parentElem.length > 0){

                //Note: order is important here to get correct order of injected DOM elements
                injectTxtAndControls();               
                injectCommentForm();
                injectErrorMsg();
                bindEvents();
            }
        }

        __construct();


        //
        // Make some events accessible from global scope
        //
        return {

        };
    };


})(jQuery, jQuery);
