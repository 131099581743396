/**
 * @dependencies LoDash|Underscore
 */
(function (ssm, window) {

    function StateManager() {
        this.callbacks = {
            'xs': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },
            'sm': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },
            'md': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },
            'lg': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },

            'mobile': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },

            'desktop': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },

            'tablet': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            },

            'lrg-desktop': {
                'enter': [],
                'leave': [],
                'resize': [],
                'firstRun': []
            }
        };
    }

    StateManager.prototype.run = function() {
        this.ssm = ssm;
        this.ssm.addState({
            id: 'xs',
            query: '(max-width: 479px)',
            onEnter: this.onEnter('xs'),
            onLeave: this.onLeave('xs'),
            onResize: this.onResize('xs'),
            onFirstRun: this.onFirstRun('xs')
        });

        this.ssm.addState({
            id: 'sm',
            query: '(max-width: 767px)',
            onEnter: this.onEnter('sm'),
            onLeave: this.onLeave('sm'),
            onResize: this.onResize('sm'),
            onFirstRun: this.onFirstRun('sm')
        });

        this.ssm.addState({
            id: 'md',
            query: '(max-width: 991px)',
            onEnter: this.onEnter('md'),
            onLeave: this.onLeave('md'),
            onResize: this.onResize('md'),
            onFirstRun: this.onFirstRun('md')
        });

        this.ssm.addState({
            id: 'lg',
            query: '(max-width: 1199px)',
            onEnter: this.onEnter('lg'),
            onLeave: this.onLeave('lg'),
            onResize: this.onResize('lg'),
            onFirstRun: this.onFirstRun('lg')
        });

        this.ssm.addState({
            id: 'mobile',
            query: '(max-width: 767px)',
            onEnter: this.onEnter('mobile'),
            onLeave: this.onLeave('mobile'),
            onResize: this.onResize('mobile'),
            onFirstRun: this.onFirstRun('mobile')
        });

        this.ssm.addState({
            id: 'tablet',
            query: '(max-width: 991px)',
            onEnter: this.onEnter('tablet'),
            onLeave: this.onLeave('tablet'),
            onResize: this.onResize('tablet'),
            onFirstRun: this.onFirstRun('tablet')
        });

        this.ssm.addState({
            id: 'desktop',
            query: '(min-width: 768px)',
            onEnter: this.onEnter('desktop'),
            onLeave: this.onLeave('desktop'),
            onResize: this.onResize('desktop'),
            onFirstRun: this.onFirstRun('desktop')
        });

        this.ssm.addState({
            id: 'lrg-desktop',
            query: '(min-width: 992px)',
            onEnter: this.onEnter('lrg-desktop'),
            onLeave: this.onLeave('lrg-desktop'),
            onResize: this.onResize('lrg-desktop'),
            onFirstRun: this.onFirstRun('lrg-desktop')
        });
    };

    StateManager.prototype.addOnEnter = function (stateId, callback) {
        if(
            typeof this.callbacks[stateId] !== 'undefined'
            && typeof this.callbacks[stateId].enter !== 'undefined'
            && typeof callback === 'function'
        ) {
            this.callbacks[stateId].enter.push(callback);
        }
    };
    StateManager.prototype.addOnLeave = function (stateId, callback) {
        if(
            typeof this.callbacks[stateId] !== 'undefined'
            && typeof this.callbacks[stateId].leave !== 'undefined'
            && typeof callback === 'function'
        ) {
            this.callbacks[stateId].leave.push(callback);
        }
    };
    StateManager.prototype.addOnResize = function (stateId, callback) {
        if(
            typeof this.callbacks[stateId] !== 'undefined'
            && typeof this.callbacks[stateId].resize !== 'undefined'
            && typeof callback === 'function'
        ) {
            this.callbacks[stateId].resize.push(callback);
        }
    };
    StateManager.prototype.addOnFirstRun = function (stateId, callback) {
        if(
            typeof this.callbacks[stateId] !== 'undefined'
            && typeof this.callbacks[stateId].firstRun !== 'undefined'
            && typeof callback === 'function'
        ) {
            this.callbacks[stateId].firstRun.push(callback);
        }
    };

    StateManager.prototype.onEnter = function (stateId) {
        var self = this;
        return function() {
            for(var i = 0; i < self.callbacks[stateId].enter.length; i++) {
                self.callbacks[stateId].enter[i].apply();
            }
        };
    };
    StateManager.prototype.onLeave = function (stateId) {
        var self = this;
        return function() {
            for(var i = 0; i < self.callbacks[stateId].leave.length; i++) {
                self.callbacks[stateId].leave[i].call();
            }
        };
    };
    StateManager.prototype.onResize = function (stateId) {
        var self = this;
        return function() {
            for(var i = 0; i < self.callbacks[stateId].resize.length; i++) {
                self.callbacks[stateId].resize[i].call();
            }
        };
    };
    StateManager.prototype.onFirstRun = function (stateId) {
        var self = this;
        return function() {
            for(var i = 0; i < self.callbacks[stateId].firstRun.length; i++) {
                self.callbacks[stateId].firstRun[i].call();
            }
        };
    };

    window.THF = window.THF || {};
    window.THF.Utility = window.THF.Utility || {};
    window.THF.Utility.StateManager = new StateManager();
})(ssm, window);

