var THF = THF || {};
THF.Interface = THF.Interface || {};

(function() {
	'use strict';

	function CampaignCapture() {
		this.cookieName = 'THF_CAMPAIGN_ENTRY';
		this.captureQueryVars = [
			'utm_source',
			'utm_medium',
			'utm_campaign',
			'utm_term',
			'utm_content'
		];
		this.queryVars = [];
		this.captureDetails();
	}
	
	
	CampaignCapture.prototype.getQueryVar = function(name) {
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
		var results = regex.exec(window.location.search);
		return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	}

	CampaignCapture.prototype.getAllQueryVars = function() {
		var params = [];
		var query = window.location.search;
		if(query) {
			query = /^[?#]/.test(query) ? query.slice(1) : query;
			return query.split('&').map(function(param) {
				var keyValue = param.split('=');
				var key = keyValue[0];
				var value = keyValue.length >= 2 ? decodeURIComponent(keyValue[1].replace(/\+/g, ' ')) : null;
				return {
					'name': key,
					'value': value
				};
			});
		}
	}
	
	CampaignCapture.prototype.captureDetails = function() {
		
		var queryVars = this.getAllQueryVars();
		if(typeof queryVars === 'undefined') {
			return;
		}
		
		for(var i = 0; i < queryVars.length; i++) {
			var qv = queryVars[i];
			if(qv.value === '') {
				continue;
			}

			if(this.captureQueryVars.indexOf(qv.name) === -1) {
				continue;
			}
			
			this.queryVars.push(qv);
		}
		
		if(this.queryVars.length > 0) {
			Cookies.set(this.cookieName, this.queryVars);	
		}
	};
	
	CampaignCapture.prototype.getCapturedDetails = function(property) {
		var data = Cookies.get(this.cookieName);
		if(typeof data === 'undefined') {
			return '';
		}
		
		var campaignProperties = JSON.parse(data);
		if(typeof property !== 'undefined') {

			var filtered = campaignProperties.filter(function(item) {
				return item.name == property;
			});
			
			return filtered.length > 0 ? filtered[0].value : '';
		}
		
		return campaignProperties;
	}

	CampaignCapture.prototype.getCapturedQueryString = function(property) {
		var data = Cookies.get(this.cookieName);
		if(typeof data === 'undefined') {
			return '';
		}

		var campaignProperties = JSON.parse(data);
		
		var queryString = campaignProperties
			.map(function(item) {
				return item.name + '=' + item.value;
			}).join('&');

		return queryString;
	}
	
	THF.Interface.CampaignCapture = new CampaignCapture();
})(jQuery);
