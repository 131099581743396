THF.Utility.Validation.addRule('thfSaveConfirmEmail', function (value, message, options) {
    var result = false;
    message = message || 'The email address must match.';

    if( options.field.name === 'email' ) {
        if (typeof options.model.confirmEmail !== 'undefined') {
            if( options.model.confirmEmail === value ) {
                result = true;
                message = '';
            }
        }
    }
    else if ( options.field.name === 'confirmEmail' ) {
        if (typeof options.model.email !== 'undefined') {
            if( options.model.email === value ) {
                result = true;
                message = '';
            }
        }
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});