(function (window, undefined) {
    'use strict';
    window.THF = window.THF || {};
    window.THF.Utility = window.THF.Utility || {};
    window.THF.Utility.Validation = (function () {
        var ruleLibrary = {};

        /**
         * Add a validation rule to the list of available validation rules.
         *
         * @param {string} name Unique Identifier to reference this rule when validating and removing.
         * @param {function} callback The callback to be called when validating the rule, please see above for example of callback.
         */
        function addRule(name, callback) {
            if (typeof callback === 'function') {
                ruleLibrary[name] = callback;
            }
        }

        /**
         * Removes a rule from the available validation rules.
         *
         * @param {string} name Unique Identifier to reference this rule when validating and removing.
         */
        function removeRule(name) {
            delete ruleLibrary[name];
        }

        /**
         * Validates the rule against a value.
         *
         * Return Example
         * <code>
         * {
         *     result: (true|false),
         *     message: 'Just a string containing some message to display to the user.'
         * }
         * </code>
         *
         * @param {string} name Unique Identifier to reference this rule when validating and removing.
         * @param {string|Array|object} value
         * @param {string} message An override message to return when the rule fails to validate.
         * @return {object}
         */
        function validateRule(name, value, message, options) {
            message = message || undefined;
            options = options || undefined;
            if (typeof ruleLibrary[name] === 'function') {
                return ruleLibrary[name].apply(null, [value, message, options]);
            }
            else {
                return {
                    result: false,
                    message: 'Rule \'' + name + '\' not found.',
                    details: []
                };
            }
        }

        /**
         * Checks if the rule exists and if so returns a boolean value.
         *
         * @param {string} name Unique Identifier to reference this rule when validating and removing.
         * @return {boolean}
         */
        function ruleExists(name) {
            return (typeof ruleLibrary[name] === 'function' );
        }

        /**
         * Outputs all the available rules to the console in a formatted manner.
         */
        function consoleRules() {
            var rules = 'Available Validation Rules\n';
            rules += '--------------------------\n';
            for (var k in ruleLibrary) {
                if (ruleLibrary.hasOwnProperty(k)) {
                    rules += k + '\n';
                }
            }
            console.log(rules);
        }
        return {
            'addRule': addRule,
            'removeRule': removeRule,
            'validateRule': validateRule,
            'ruleExists': ruleExists,
            'consoleRules': consoleRules
        };

    })();
})(window);
