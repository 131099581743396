THF.Utility.Validation.addRule('thfDateCallback', function (value, message, options) {
    var result = false;
    message = message || 'Must be a valid date i.e. dd/mm/yyyy';
    var now = moment().tz('Australia/Sydney');
    // Ensure the current date starts at midnight this morning.
    var currentDate = moment().tz('Australia/Sydney').hour(0).minute(0).second(0);
    var valueDate;

    var REGEX = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
    if (REGEX.test(value)) {
        result = true;
        valueDate = moment(value, 'DD/MM/YYYY', 'Australia/Sydney').hour(23).minute(59).second(59);
    }

    if (result) {
        if(now.isSame(valueDate, 'day') && (now.hour() >= 17 || (now.hour() >= 16 && now.minute() >= 30))) {
            result = false;
            message = 'The current date cannot be selected after 4:30 pm' + ((now.isDST() ? ' AEDT' : ' AEST'));
        }
    }


    // Check if the date is actually a valid date.
    if (result) {
        result = valueDate.isValid();
        message = 'Invalid Date Format';
    }

    var i;
    if (result) {
        if (
            typeof options !== 'undefined'
            && typeof options.invalidDates !== 'undefined'
            && typeof options.invalidDates.length !== 'undefined'
        ) {
            for(i=0; i < options.invalidDates.length; i++) {
                var invalidDate = moment(options.invalidDates[i].Date).tz('Australia/Sydney').hour(0).minute(0).second(0);
                if(valueDate.format('YYYY-MM-DD') === invalidDate.format('YYYY-MM-DD')) {
                    result = false;
                    message = 'Callback is not available on this date, please select another date.';
                }
            }
        }
    }

    // Date is not in the past.
    if (result) {
        result = (valueDate.isAfter(currentDate));
        if (!result) {
            message = 'Date cannot be in the past.';
        }
    }

    if (result) {
        var validDates = [];
        var numDays = 6;
        var runningDate = moment().tz('Australia/Sydney');
        for(i=0; i < 30; i++) {
            if (
                typeof options !== 'undefined'
                && typeof options.invalidDates !== 'undefined'
                && typeof options.invalidDates.length !== 'undefined'
            ) {
                for (var j = 0; j < options.invalidDates.length; j++) {
                    invalidDate = moment(options.invalidDates[j].Date).tz('Australia/Sydney').hour(0).minute(0).second(0);
                    if(
                        runningDate.day() !== 0 && runningDate.day() !== 6
                        && runningDate.format('YYYY-MM-DD') !== invalidDate.format('YYYY-MM-DD')
                    ) {
                        validDates.push(runningDate.format('YYYY-MM-DD'));
                        break;
                    }
                }
            }

            if(validDates.length === numDays) {
                break;
            }
            runningDate = runningDate.add(1, 'd');
        }

        var validDateCount = validDates.filter(function(item) {
            return (item === valueDate.format('YYYY-MM-DD'));
        });
        result = validDateCount.length > 0;
        if (!result) {
             message = 'Date must be within the 6 working days.';
        }
    }

    if (result) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});