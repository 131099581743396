THF.Utility.Validation.addRule('requiredCheckbox', function (value, message) {
    var result = false;
    message = message || 'This field is required.';

    if (typeof value !== 'undefined') {
        if (typeof value === 'number') {
            result = true;
        }
        else if ( value.length && value.length > 0) {
            result = true;
        }
        else if( Object.keys && Object.keys(value).length > 0 ) {
            result = true;
        }
    }

    // Assume we are using the Y/N convention from the join form.
    if( result && value !== 'Y' ) {
        result = false;
    }
    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});