THF.Utility.Validation.addRule('thfSaveMatchDOB', function (value, message, options) {
    var result = false;
    message = message || 'Your date of birth does not match.';

    if( value === options.globalModel.cover.memberDOB ) {
        result = true;
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});