(function($, w, d, undefined){
    'use strict';

    function SearchDesktop(options) {
        this.searchOpen = false;
        this.options = {
            parent: '#master-header',
            btnSearchTrigger: '.cta-links .search',
            btnClose: '.search-desktop .btn-close-search',
            searchDropdown: '.search-desktop',
            tintLayer: '.search-tint',
            searchInput: '.desktop-search-input',
            btnSubmit: '.btn-search-submit',
            urlBase: '/search?q='
        };

        this.options = $.extend({}, this.options, options);

        this.$parent = $(this.options.parent);
        this.$btnSearchTrigger = this.parent(this.options.btnSearchTrigger);
        this.$btnClose = this.parent(this.options.btnClose);
        this.$searchDropdown = this.parent(this.options.searchDropdown);
        this.$tintLayer = this.parent(this.options.tintLayer);
        this.$searchInput = this.parent(this.options.searchInput);
        this.$btnSubmit = this.parent(this.options.btnSubmit);

        if (this.$parent.length > 0){
            this.bindEvents();

        } else {
            return;
        }
    }


    SearchDesktop.prototype.parent = function(selector) {
        return $(selector, this.$parent);
    };

    SearchDesktop.prototype.openSearch = function(){
        this.$parent.addClass('search-open');
        this.$searchDropdown.addClass('search-open');
        this.searchOpen = true;
    };

    SearchDesktop.prototype.closeSearch = function(){
        this.$parent.removeClass('search-open');
        this.$searchDropdown.removeClass('search-open');
        this.searchOpen = false;
    };

    SearchDesktop.prototype.toggleSearch = function(){
        if(this.searchOpen){
            this.closeSearch();
        } else {
            this.openSearch();
        }
    };

    SearchDesktop.prototype.submitSearch = function(e) {
        var searchString = this.$searchInput.val();
        if (searchString) {
            return true;
        } else {
            e.preventDefault();
        }
    };

    SearchDesktop.prototype.closeonScroll = function(){

        if (document.body.scrollTop > 450 || document.documentElement.scrollTop > 450) {
            if(this.searchOpen){
                this.closeSearch();
            }
        }
    }

    SearchDesktop.prototype.bindEvents = function(){
        var self = this;

        this.$btnSearchTrigger.click(function(e){
            self.toggleSearch();
            e.preventDefault();
        });

        this.$tintLayer.click(function(){
            self.closeSearch();
        });

        this.$btnClose.click(function(){
            self.closeSearch();
        });

        this.$btnSubmit.click(function(e){
            self.submitSearch(e);
        });

        this.$searchInput.keyup(function(event) {
            if (event.keyCode === 13) {
                self.submitSearc(e);
            }
        });

        //Setup a minimal scroll listener
        window.onscroll = function() {
            self.closeonScroll()
        };
    };


    w.THF = w.THF || {};
    w.THF.Interface = w.THF.Interface || {};
    w.THF.Interface.SearchDesktop = SearchDesktop;


})(jQuery, window, document);