THF.Utility.Validation.addRule('thfAccountNumber', function (value, message) {
    var result = false;
    message = message || 'You must enter a valid account number.';

    if( parseInt(value,10) > 0 ) {
        result = true;
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});