/**
 * NOTE: this rule cannot be used in the dependants section nor can it be used with the save modal.
 */
THF.Utility.Validation.addRule('thfPreValidated', function (value, message, options) {
    var result = false;
    message = message || 'You must enter a valid value.';

    if( typeof options.formState.validation[options.field.name] === 'boolean' ) {
        if( options.formState.validation[options.field.name] ) {
            result = true;
            message = '';
        }
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});