THF.Utility.Validation.addRule('alphanumeric', function (value, message) {
    var result = false;
    message = message || 'This field must contain only characters, numbers and spaces.';

    var REGEX = /^[a-zA-Z0-9 ]*$/;

    if( REGEX.test(value) ) {
        result = true;
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});