THF.Utility.Validation.addRule('thfDateCoverStart', function (value, message) {
    var result = false;
    message = message || 'Must be a valid date i.e. dd/mm/yyyy';

    // Ensure the current date starts at midnight this morning.
    var currentDate = moment().hour(0).minute(0).second(0);

    var valueDate;

    var REGEX = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
    if( REGEX.test(value) ) {
        result = true;
        valueDate = moment(value).hour(23).minute(59).second(59);
    }

    // Check if the date is actually a valid date.
    if( result ) {
        result = valueDate.isValid();
        message = 'Invalid Date via MomentJS';
    }

    // Date is not in the past.
    if( result ) {
        result = (valueDate.isAfter(currentDate));
        if( !result ) {
            message = 'Date cannot be in the past.';
        }
    }

    // Date is not in beyond 2 months from now.
    if( result ) {
        result = valueDate.isBefore(moment().hour(0).minute(0).second(0).add(2, 'months').add(1,'day'));
        if( !result ) {
            message = 'Date cannot be beyond 2 months from current date.';
        }
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});