var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($, jQuery) {
	'use strict';

	THF.Interface.PageUtility = function( options ) {

		var defaults = {
			resizeLimitLower: 5,
			resizeLimitUpper: 60,

			selectors: {
				parentElem: '.page-utility',
				btnPrint: '.print',
				btnEmail: '.email',
				btnShare: '.share',
				btnIncreaseFont: '.font-resize.increase',
				btnDecreaseFont: '.font-resize.decrease',
				resizeElement: '.resize-wrapper'
			},
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parentElem = $(options.selectors.parentElem);
		var btnPrint = $parentElem(options.selectors.btnPrint);
		var btnEmail = $parentElem(options.selectors.btnEmail);
		var btnShare = $parentElem(options.selectors.btnShare);
		var btnIncreaseFont = $parentElem(options.selectors.btnIncreaseFont);
		var btnDecreaseFont = $parentElem(options.selectors.btnDecreaseFont);
		var resizeElement = $(options.selectors.resizeElement);


		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Init sharing config
		/* DEV NOTES:
		This function has a hard dependency on the ShareThis library.
		This configures and inits the sharing options, which are heavily dependant
		on class structure in the markup and 3rd party JS library reference.
		Sharing is ONLY applicable from the PageUtility component.

		--See /Views/Partials/Common/_PageUtility.cshtml for markup and
		library refences.

		--Element wiht 'st_sharethis_large' (3rd party defined) class hooks into 3rd party lib and binds sharing functionality.

		**********************************/
		function initSharingConfig(){
			stLight.options({
				publisher: "0f53148f-9e2f-4f39-aace-03d615f97e86",
				doNotHash: false,
				doNotCopy: false,
				hashAddressBar: false,
				ui_click: true
			});
		}




		/**********************************
		// Interface events
		**********************************/
		function pagePrint(){
			window.print();
		}

		function resizeText(resizeAmount) {

		    resizeElement.find('*').each(
		        function(index){

		            var currentSizePx = $(this).css('font-size');
		            var currentSize = parseInt(currentSizePx, 10);

		            if (!currentSize) {
		                currentSize = 16;
		            }

		            var newSize = currentSize + resizeAmount;

		            if ((newSize > options.resizeLimitUpper) || (newSize < options.resizeLimitLower)) {

		                return false;

		            } else {

		                $(this).css('font-size', newSize);
		            }
		        }
		    )
		}

		/**********************************
		// Event binding
		**********************************/
		function bindEvents(){

			btnPrint.click(function(e){
				pagePrint();
			});

			btnIncreaseFont.click(function(e){
				resizeText(1);
				e.preventDefault();
			});

			btnDecreaseFont.click(function(e){
				resizeText(-1);
				e.preventDefault();
			});
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			// Init
			if (parentElem.length > 0){

				bindEvents();

				if (typeof stLight !== "undefined"){
                    initSharingConfig();
                }
			}
		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
		};
	};


})(jQuery, jQuery);
