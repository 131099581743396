/**
 * Rule: Required
 *
 * Checks if the value is not empty and returns the result.
 */
THF.Utility.Validation.addRule('required', function (value, message) {
    var result = false;
    message = message || 'This field is required.';

    if (typeof value !== 'undefined' && value !== null) {
        if (typeof value === 'number') {
            result = true;
            message = '';
        }
        else if ( value.length && value.length > 0) {
            result = true;
            message = '';
        }
        else if( typeof value === 'object' && Object.keys && Object.keys(value).length > 0 ) {
            result = true;
            message = '';
        }
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});