/**
 * Rule: Integer
 *
 * Checks if the value is an integer and returns the result.
 */
THF.Utility.Validation.addRule('integer', function (value, message) {
    var result = false;
    message = message || 'Please enter a valid email address.';

    // We want to detect if there are 0 or more digits as an empty value can be considered valid.
    // If you need to check required use the required rule.
    var REGEX = '/^\d*$/';

    if (typeof value !== 'undefined' && value.length > 0) {
        result = true;
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});