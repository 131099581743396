/**
 * @dependencies LoDash|Underscore
 */
(function (window, _) {

    window.THF = window.THF || {};
    window.THF.Utility = window.THF.Utility || {};
    window.THF.Utility.DateHelper = (function () {

        /**
         * Gets the current age from the coverStartDate to the dob passed in.
         *
         * @param dob
         * @param effectiveDate ISO format
         * @returns {*}
         */
        function getAge(dob, effectiveDate) {
            var current = moment(effectiveDate, 'YYYY-MM-DD').hour(0).minute(0).second(0);
            dob = moment(dob, 'YYYY-MM-DD').hour(0).minute(0).second(0);
            return ( current.diff(dob, 'years') );
        }

        /**
         *
         * @param {string} dob
         * @returns {moment}
         */
        function getLHCBaseDate(dob) {
            dob = moment(dob, 'YYYY-MM-DD').hour(0).minute(0).second(0);
            var lhcBaseDate = moment(dob).add(31, 'y').month(6).date(1).hour(0).minute(0).second(0);
            if (dob.month() >= 6) {
                lhcBaseDate.add(1, 'y');
            }
            return lhcBaseDate;
        }


        /**
         * Calculates a persons Certified Age of Entry (CAE) for the Loading Health Cover.
         *
         * If the person has had continuous cover then return the age of 30.
         *
         * If the person is under the age of 31 and do not have continuous cover then we do not need
         * to return the age as they are not eligible.
         *
         * Otherwise we need to send back the calculated age of entry.
         *
         * @param dob
         * @param effectiveDate
         * @param continuousCover
         * @returns {*}
         */
        function getLHCAge(dob, effectiveDate, continuousCover) {
            continuousCover = continuousCover || false;
            if (continuousCover) {
                return 30;
            }

            var currentDate = moment().hour(0).minute(0).second(0);
            effectiveDate = moment(effectiveDate, 'YYYY-MM-DD').hour(0).minute(0).second(0);
            dob = moment(dob, 'YYYY-MM-DD').hour(0).minute(0).second(0);

            // If the member purchases cover for the first time* on or after 23 April 2004, and their
            // birthday is not 1 July , their lifetime health cover age is their age on the 1 July before
            // they purchase hospital cover for the first time (unless they purchase cover for the first
            // time on 1 July, in which case their lifetime health cover age is their age on the day they
            // purchase cover)

            // If the member purchases cover for the first time* on or after 23 April 2004, and their birthday
            // is 1 July, their lifetime health cover age is 1 year less than their actual age on the day they
            // purchase hospital cover for the first time

            var financialYear = currentDate.format('YYYY');
            if (effectiveDate.format('MM-DD') < '07-01') {
                financialYear--;
            }

            // lifetime health cover age is their age on the 1 July before they purchase hospital cover for the first time
            if (dob.format('MM-DD') == '07-01') {
                financialYear--;
            }
            var from = moment(financialYear + '-07-01', 'YYYY-MM-DD');

            var cae = parseInt(from.diff(dob, 'years'), 10);
            if (!continuousCover && cae > 30) {
                return cae;
            }
            return null;
        }

        /**
         * Calculates a persons Certified Age of Entry (CAE) for the Loading Health Cover.
         *
         * If the person has had continuous cover then return the age of 30.
         *
         * If the person is under the age of 31 and do not have continuous cover then we do not need
         * to return the age as they are not eligible.
         *
         * Otherwise we need to send back the calculated age of entry.
         *
         * @param dob
         * @param effectiveDate
         * @param continuousCover
         * @returns {*}
         */
        function getLHCAgeV2(dob, effectiveDate, continuousCover) {
            continuousCover = continuousCover || false;
            if (continuousCover) {
                return 30;
            }

            var currentDate = moment().hour(0).minute(0).second(0);
            effectiveDate = moment(effectiveDate, 'YYYY-MM-DD').hour(0).minute(0).second(0);
            dob = moment(dob, 'YYYY-MM-DD').hour(0).minute(0).second(0);

            // If their Age is below 31 as of cover start date, then ignore the rules below
            // and return the current age as of cover start date.
            var ageFromEffectiveDate = parseInt(effectiveDate.diff(dob, 'years'), 10);
            if( ageFromEffectiveDate < 31 ) {
                return ageFromEffectiveDate;
            }

            // If the member purchases cover for the first time* on or after 23 April 2004, and their
            // birthday is not 1 July , their lifetime health cover age is their age on the 1 July before
            // they purchase hospital cover for the first time (unless they purchase cover for the first
            // time on 1 July, in which case their lifetime health cover age is their age on the day they
            // purchase cover)

            // If the member purchases cover for the first time* on or after 23 April 2004, and their birthday
            // is 1 July, their lifetime health cover age is 1 year less than their actual age on the day they
            // purchase hospital cover for the first time

            var financialYear = effectiveDate.format('YYYY');
            if (effectiveDate.format('MM-DD') < '07-01') {
                financialYear--;
            }

            // lifetime health cover age is their age on the 1 July before they purchase hospital cover for the first time
            if (dob.format('MM-DD') == '07-01') {
                financialYear--;
            }
            var from = moment(financialYear + '-07-01', 'YYYY-MM-DD');



            return parseInt(from.diff(dob, 'years'), 10);
        }

        return {
            'getAge': getAge,
            'getLHCBaseDate': getLHCBaseDate,
            'getLHCAge': getLHCAgeV2
        };
    })();
})(window, _);

