var THF = THF || {};
THF.Interface = THF.Interface || {};

(function($, jQuery) {
	'use strict';

	THF.Interface.GlobalAlert = function( options ) {
		
		var activeAlert = null;

		var defaults = {
			animateSpeed: 350,
			selectors: {
				parentElem: '.global-alert',
				alertCloseBtn: '.btn-close'

			},
			settings: {
				currentPage: 0,
				alerts: []
			}
		};

		options = $.extend(true, {}, defaults, options );
		

		/**********************************
		// Declare variables
		**********************************/
		var parentElem = $(options.selectors.parentElem);
		var alertCloseBtn = $parentElem(options.selectors.alertCloseBtn);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector); 
		}

		/**********************************
		// Cookie functions
		**********************************/
		function storeCookie(value){
			var cookieName = 'THF.GlobalAlertClosed-' + activeAlert.id;
			var cookieOptions = {};
			if (activeAlert.cookieExpirationOverride) {
				cookieOptions.expires = activeAlert.cookieExpirationDays;
			}
			Cookies.set(cookieName, value, cookieOptions);
		}


		/**********************************
		// Interface events
		**********************************/
		function showAlert(alert){
			// parentElem 
			parentElem.addClass(alert.alertColour.toLowerCase())
			$('.message', parentElem).html(alert.alertText);
			parentElem.show();
		}

		function hideAlert(){
			parentElem.velocity("slideUp", {
				duration: options.animateSpeed,

				complete: function(){
					storeCookie(true);
				}
			});
		}

		function bindEvents(){

			alertCloseBtn.click(function(e) {
				hideAlert();
				e.preventDefault();
			});
		}
		
		function getActiveAlert() {
			var now = new Date();
			var stringCurrentDateTime = 
				now.getFullYear() +
				'-' + (now.getMonth()+1).toString().padStart(2, 0) +
				'-' + now.getDate().toString().padStart(2, 0) +
				'T' + now.getHours().toString().padStart(2, 0) +
				':' + now.getMinutes().toString().padStart(2, 0) +
				':' + now.getSeconds().toString().padStart(2, 0);
			
			var activeAlerts = options.settings.alerts.filter(function(item) {
				var startValid = item.startDate <= stringCurrentDateTime;
				var endValid = item.endDate >= stringCurrentDateTime;
				
				
				
				var isEnabled = true;
				if(item.rules.length > 0) {
					isEnabled = false; // If the page rules are in place, we want to disable all placements.
					for(var i = 0; i < item.rules.length; i++) {
						var rule = item.rules[i];
						var inPageList = rule.pages.filter(page => parseInt(page, 10) == parseInt(options.settings.currentPage, 10)).length > 0;
						if(inPageList) {
							if(rule.ruleType) { // Enabled on Page
								isEnabled = true;
							}
							else { // Disabled on Page
								isEnabled = false;
							}
						}
					}
				}
				else { // Deprecated Functionality.
					isEnabled = item.disableOnPages.filter(page => parseInt(page, 10) == parseInt(options.settings.currentPage, 10)).length === 0; // Deprecated
				}
				
				return startValid && endValid && isEnabled;
			});
			
			activeAlerts.sort(function(a, b) {
				if(a.startDate == b.startDate) {
					return 0;
				}
				return (a.startDate < b.startDate ? -1 : 1);
			});
			
			if(activeAlerts.length > 0) {
				return activeAlerts[0];
			}
			return null;
		}
		
		
		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {
			
			activeAlert = getActiveAlert();
			if(activeAlert == null) {
				return;
			}
			
			// Init
			var cookieCheck = Cookies.get('THF.GlobalAlertClosed-' + activeAlert.id);

			if ((parentElem.length > 0) && (! cookieCheck )){
				bindEvents();
				showAlert(activeAlert);
			}
		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
		};
	};


})(jQuery, jQuery);
