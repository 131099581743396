THF.Utility.Validation.addRule('medicare', function (value, message) {
    var result = false;
    message = message || 'This number must be a valid Medicare Card number.';

    var iMedicareNumber = value.replace(/[^0-9]/g,'');
    if( iMedicareNumber.length == 10 ) {
        var aMatches = iMedicareNumber.match(new RegExp('^([0-9]{8})([0-9])'));
        var iBase = aMatches[1];
        var iCheckDigit = aMatches[2];
        var iSum = 0;
        var aWeights = [1,3,7,9,1,3,7,9];

        for( var i = 0; i < aWeights.length; i++ ) {
            iSum += iBase.charAt(i) * aWeights[i];
        }
        result = ( (iSum % 10) === parseInt( iCheckDigit, 10 )  );
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});