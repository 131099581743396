(function(window, document, $, undefined){
    'use strict';
    window.THF = window.THF || {};
    window.THF.Utility = window.THF.Utility || {};
    window.THF.Utility.Location = (function() {

        /**
         * Gets a query string parameter from the URL, if applicable.
         *
         *
         * Best answer by far.
         * @see http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
         *
         * @param name
         * @returns {string}
         */
        function getParameterByName(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(window.location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        }


        /**
         * Navigates the user to the Join form.
         */
        function navigateToJoinForm() {
            window.location = '/join-form/';
        }

        /**
         * Navigates the user to the live quore tool.
         *
         * NOTE: there is a hard coded URL for the live quote page in the code below.
         *
         * @param {string} state
         * @param {string} membershipType
         * @param {string} hospitalCover
         * @param {string} ancillaryCover
         * @param {string} age
         */
        function navigateToLiveQuoteTool(state, membershipType, hospitalCover, ancillaryCover, age) {
            var hrc = THF.Utility.HealthRateCode.encode(state, membershipType, hospitalCover, ancillaryCover);

            var queryString = {
                'hrc': hrc,
                'age': age
            };

            return '/live-quote-tool?'+ $.param(queryString);
        }

        function generateLiveQuoteToolShareUrl(state, membershipType, hospitalCover, ancillaryCover, age, extraParams) {
            extraParams = extraParams || {};
            var hrc = THF.Utility.HealthRateCode.encode(state, membershipType, hospitalCover, ancillaryCover);

            var queryString = {
                'hrc': hrc,
                'age': age
            };
            queryString = $.extend( true, {}, queryString, extraParams);

            if( typeof queryString.asAtDateApril !== 'undefined' ) {
                queryString.asAtDateApril = ( queryString.asAtDateApril == true ) ? '1' : '0';
            }

            if( typeof queryString.extended !== 'undefined' ) {
                queryString.extended = ( queryString.extended == true ) ? '1' : '0';
            }

            var domain = window.location.protocol+'//'+window.location.hostname;
            return domain+'/live-quote-tool?'+$.param(queryString);
        }

        function cacheBustUrl(url) {
            var timestamp = Math.round(new Date().getTime() / 1000);
            if(url.indexOf('?') >= 0) {
                url += '&';
            }
            else {
                url += '?';
            }
            return url + 'v='+timestamp;
        }



        return {
            'navigateToLiveQuoteTool': navigateToLiveQuoteTool,
            'navigateToJoinForm': navigateToJoinForm,
            'queryParam': getParameterByName,
            'generateLiveQuoteToolShareUrl': generateLiveQuoteToolShareUrl,
            'cacheBustUrl': cacheBustUrl
        };

    })();
})(window,document,jQuery);
