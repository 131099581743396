(function (window) {
    
    window.THF = window.THF || {};
    window.THF.Utility = window.THF.Utility || {};
    window.THF.Utility.DatalayerTracking = (function () {
        var inViewportTrackers = [];
        
        function track(eventName, eventParams) {
            if(typeof window.dataLayer === 'undefined') {
                return;
            }
            
            var payload = Object.assign( {event: ''}, eventParams, {event: eventName});
            window.dataLayer.push(payload);
        }
        
        function trackIfElementInViewport(element, eventName, eventParams) {
            inViewportTrackers.push({
                element: element,
                eventName: eventName,
                eventParams: eventParams,
                inViewport: false
            });
        }

        
        function isElementInViewport (el) {
            // Special bonus for those using jQuery
            if (typeof window.jQuery === "function" && el instanceof window.jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
        
        function onShownInViewport(e) {
            for(var tracker of inViewportTrackers) {
                // console.log(tracker.element, tracker.inViewport);
                // console.log(tracker.eventName, tracker.eventParams)

                if(!tracker.inViewport && isElementInViewport(tracker.element)) {
                    tracker.inViewport = true;
                    track(tracker.eventName, tracker.eventParams);
                }
                else if(tracker.inViewport && !isElementInViewport(tracker.element)) {
                    tracker.inViewport = false;
                }
            }
        }
        
        
        function bindEvents() {
            window.addEventListener('onDomContentLoaded', onShownInViewport, false);
            window.addEventListener('load', onShownInViewport, false);
            window.addEventListener('scroll', onShownInViewport, false);
            window.addEventListener('resize', onShownInViewport, false);
        }
        
        function __construct() {
            bindEvents();    
        }

        __construct();
        
        return {
            track,
            trackIfElementInViewport
        }
    })();
})(window);