(function (window, document, undefined) {
    'use strict';
    /**
     * DSTAPI is an interface to the various stats tracking packages out there,
     * it provides its flexibility by allowing you to plug multiple engines
     * into the interface to provide multiple tracking engines by one call,
     * it also provides flexibility by allowing you to swap out all your
     * tracking code for newer versions if a provider changes their interface.
     */
    var dstapiCore = function () {
        /**
         * The current version of DSTAPI.
         *
         * @type {string}
         */
        var sVersion = '3.0.0';

        /**
         * Flag to mark if the library should spit out any messaging or not.
         *
         * @type {boolean}
         */
        var bDebug = false;

        /**
         * Contains all the engines that are called when a call command is made.
         *
         * To add an engine to this list please use addEngine after you have
         * included the DSTAPI library, there is no need to modify this code
         * anymore.
         *
         * @type {Array}
         */
        var aEngines = [];

        /**
         * Adds an engine to the callable list, see example above for creating
         * an engine to be passed in.
         *
         * @param {function}    Engine    A function reference which returns an object of named functions.
         * @param {object}      oOptions  An object with the options for the engine.
         */
        function addEngine(Engine, oOptions) {
            if (typeof Engine === 'function') {
                aEngines.push(Engine.call(null, oOptions));
            }
            else {
                error('Engine must be a callable function, please check documentation for an example.');
            }
        }

        /**
         * Clears all assigned engines, this was built primarily be able
         * to remove the engines when inside of the unit tests.
         */
        function removeEngines() {
            aEngines = [];
        }

        /**
         * Returns the list of engines attached to DSTAPI.
         *
         * @return {Array}
         */
        function getEngines() {
            return aEngines;
        }

        /**
         * Adds a plugin to the system, please see the documentation for the
         * format to follow for this plugin.
         *
         * This has been expanded to include the functionality of running its own function.
         *
         * @param {function|object}  Plugin  The plugin to be running.
         */
        function addPlugin(Plugin) {
            if (typeof Plugin === 'function') {
                Plugin.call(null);
            }
            else {
                error('Plugins are effectively callbacks, so you must pass a {function} in.');
            }
        }

        /**
         * Returns the current running version of DSTAPI.
         */
        function version() {
            return sVersion;
        }

        /**
         * Generic call that calls the various actions from within an engine,
         * if the engine does not support an action the call is not made, if
         * this class is in development mode then it outputs an error to say
         * that the action does not exist.
         *
         * @param {string} sAction The action from ann engine to run.
         * @param {object} oOptions An object with the options to pass to the call.
         */
        function track(sAction, oOptions) {
            sAction = sAction || 'page'; // page is the default action.

            var iEngineLength = aEngines.length;
            if (iEngineLength > 0) {
                var i = 0;
                for (i; i < iEngineLength; i++) {
                    if (typeof aEngines[i][sAction] === 'function') {
                        aEngines[i][sAction].call(null, oOptions);
                    }
                }
            }
            else {
                error('No engines were defined, you must specify an engine.');
            }
        }


        /**
         * Sets the debug status, by default debugging is set to false.
         *
         * @param {boolean} bStatus
         */
        function debug(bStatus) {
            bDebug = bStatus;
        }

        /**
         * Logs a message to the console if it exists.
         *
         * @param {*} data
         */
        function log(data) {
            if (bDebug && console && console.log) {
                console.log(data);
            }
        }

        /**
         * Logs an error to the console if it exists.
         *
         * @param {*} data
         */
        function error(data) {
            if (bDebug && console && console.error) {
                console.error(data);
            }
        }

        /**
         * Returns the publicly accessible methods.
         */
        return {
            'addEngine': addEngine,
            'removeEngines': removeEngines,
            'getEngines': getEngines,
            'addPlugin': addPlugin,
            'track': track,
            'debug': debug,
            'log': log,
            'error': error,
            'version': version
        };
    };

    // Initialise the DSTAPI Library as a root level object.
    window.DSTAPI = window.DSTAPI || {};
    window.DSTAPI.Core = new dstapiCore();

    /**
     * The only call that should have some semblance of convenience is the
     * "call" method as this will be used everywhere across the site.
     */
    window.DSTAPI.track = window.DSTAPI.Core.track;
})(window, document);