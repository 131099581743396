THF.Utility.Validation.addRule('thfDateOfBirthDependant', function (value, message) {
    var result = false;
    message = message || 'Must be a valid date i.e. dd/mm/yyyy';

    // Ensure the current date starts at midnight this morning.
    var currentDate = moment();
    currentDate.hour(0).minute(0).second(0);
    var valueDate;

    var REGEX = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
    if( REGEX.test(value) ) {
        result = true;
        valueDate = moment(value);
    }

    // Check if the date is actually a valid date.
    if( result ) {
        result = valueDate.isValid();
    }

    // Cannot be a date in the future.
    if( result ) {
        result = valueDate.isBefore(currentDate);
        if( !result ) {
            message = 'Date must be in the past.';
        }
    }

    if( result ) {
        
        var maxAge = parseInt(joinFormPageData.dependantMaxAge) + 1;
        result = valueDate.isAfter(moment().hour(0).minute(0).second(0).subtract(maxAge, 'years').subtract(2,'days'));
        
        if( !result ) {
            message = 'Cannot be a date more than '+joinFormPageData.dependantMaxAge+' years in the past.';
        }
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});