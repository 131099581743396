/**
 * @dependencies LoDash|Underscore
 */
(function (window, _) {

    window.THF = window.THF || {};
    window.THF.Utility = window.THF.Utility || {};
    window.THF.Utility.HealthRateCode = (function () {

        var states = {
            'NSW': 'N',
            'QLD': 'Q',
            'VIC': 'V',
            'TAS': 'T',
            'ACT': 'A',
            'SA': 'S',
            'WA': 'W',
            'NT': 'D'
        };

        var membershipTypes = {
            'Sgl': 'S',    // Single
            'Cpl': 'C',    // Couple
            'Fam': 'F',    // Family
            'FamX': 'H',   // Family Extended
            'SPFam': 'G',  // Single Parent
            'SPFamX': 'L'  // Single Parent Extended
        };

        var hospitalCovers = {
            'GH':     'Z',    // Gold Hospital
            'TH':     'X',    // Top Hospital
            'PHS':    'N',    // Top Hospital 300
            'SS':     'S',    // Top Hospital 500
            'Mid3':   'F',    // Mid Hospital 300
            'Mid5':   'G',    // Mid Hospital 500
            'MC300':  'T',    // Mid Silver Plus
            'MC500':  'U',    // Mid Silver Plus
            'BasHo':  'H',    // Basic Hospital
            'SP':     'P'     // Starter Pak
        };

        var ancillaryCovers = {
            'Anc': 'C',   // Top Extras
            'EHC': 'Y',   // Essential Extras
            'BE': 'W',    // Basic Extras
            'Amb': 'A'    // Ambulance
        };


        /**
         * This remaps the special overrides in place for hospital cover type codes when
         * in the states, NSW, ACT and VIC.
         *
         * This is primarily used during the decode process.
         *
         * @param {string} code The single character code from the encoded value.
         * @returns {string}
         */
        var decodeSpecialOverride = function( code ) {
            // Correct the NSW, ACT and VIC codebase
            // NOTE: there is an assumption here that the cover codes are unique between the hospital and ancillary character codes.
            if (code === 'K') {
                code = 'X';
            }
            else if (code === 'I') {
                code = 'N';
            }
            return code;
        };

        /**
         * Encodes a product type into a 3 or 4 character code.
         *
         * @param {string} state
         * @param {string} membershipType
         * @param {string} hospitalCover
         * @param {string} ancillaryCover
         * @returns {string|boolean} A 3 or 4 character code, if it does not match 3 or 4 characters it returns boolean false.
         */
        var encode = function (state, membershipType, hospitalCover, ancillaryCover) {
            var stateCode = (typeof states[state] !== 'undefined') ? states[state] : '';
            var membershipTypeCode = (typeof membershipTypes[membershipType] !== 'undefined') ? membershipTypes[membershipType] : '';
            var hospitalCoverCode = (typeof hospitalCovers[hospitalCover] !== 'undefined') ? hospitalCovers[hospitalCover] : '';
            var ancillaryCoverCode = (typeof ancillaryCovers[ancillaryCover] !== 'undefined') ? ancillaryCovers[ancillaryCover] : '';

            if (state === 'NSW' || state === 'ACT' || state === 'VIC' ) {
                if (hospitalCover === 'TH') {
                    hospitalCoverCode = 'K';
                }
                else if (hospitalCover === 'PHS') {
                    hospitalCoverCode = 'I';
                }
            }

            var code = stateCode + membershipTypeCode + hospitalCoverCode + ancillaryCoverCode;

            if( code.length >= 3 ) {
                return code;
            }
            return false;
        };

        /**
         * Decodes a health rate code and returns an object that contains
         * the original values used to give the health rate code.
         *
         * @param {string} code a 3 or 4 character code.
         * @return {object}
         */
        var decode = function (code) {
            var result = false;
            var response = {
                state: '',
                membershipType: '',
                hospitalCover: '',
                ancillaryCover: ''
            };

            var stateMap = _.invert(states);
            var membershipTypeMap = _.invert(membershipTypes);
            var hospitalCoverMap = _.invert(hospitalCovers);
            var ancillaryCoverMap = _.invert(ancillaryCovers);

            var stateCode = '';
            var membershipTypeCode = '';
            var hospitalCoverCode = '';
            var ancillaryCoverCode = '';

            if (code.length === 4) {
                stateCode = code[0];
                membershipTypeCode = code[1];
                hospitalCoverCode = decodeSpecialOverride(code[2]);
                ancillaryCoverCode = code[3];
                result = true;
            }
            else if (code.length === 3) {
                stateCode = code[0];
                membershipTypeCode = code[1];

                // Detect if the code is a Hospital Cover or a Ancillary Cover
                var coverCode = decodeSpecialOverride(code[2]);

                if (typeof hospitalCoverMap[coverCode] !== 'undefined') {
                    hospitalCoverCode = coverCode; 
                    ancillaryCoverCode = '';
                    result = true;
                }
                else if (typeof ancillaryCoverMap[coverCode] !== 'undefined') {
                    hospitalCoverCode = '';
                    ancillaryCoverCode = coverCode;
                    result = true;
                }
            }

            if (typeof stateMap[stateCode] !== 'undefined') {
                response.state = stateMap[stateCode];
            }
            else {
                result = false;
            }

            if (typeof membershipTypeMap[membershipTypeCode] !== 'undefined') {
                response.membershipType = membershipTypeMap[membershipTypeCode];
            }
            else {
                result = false;
            }

            if (typeof hospitalCoverMap[hospitalCoverCode] !== 'undefined') {
                response.hospitalCover = hospitalCoverMap[hospitalCoverCode];
            }
            else {
                response.hospitalCover = '';
            }

            if (typeof ancillaryCoverMap[ancillaryCoverCode] !== 'undefined') { 
                response.ancillaryCover = ancillaryCoverMap[ancillaryCoverCode];
            }
            else {
                response.ancillaryCover = '';
            }

            if (result) {
                return response;
            }
            return false;
        };


        return {
            'encode': encode,
            'decode': decode
        };
    })();
})(window, _);
