
// Rules from Functional Specification
// -----------------------------------------------------
// - Cannot be a date in the past
// - Cannot be a date before the Cover Start Date
// - Can only be a date between the cover start date and a value in the future based on the Payment Frequency Field.
//      - Fortnightly then it can only be 14 days from the cover start date.
//      - Monthly then it can only be a calendar month from the cover start date.
//      - Quarterly then it can only be a calendar month from the cover start date.
//      - Half Yearly then it can only be a calendar month from the cover start date.
//      - Yearly then it can only be a calendar month from the cover start date.
THF.Utility.Validation.addRule('thfDeductionDate', function (value, message, options) {
    var result = false;
    message = message || 'Must be a valid date i.e. dd/mm/yyyy';

    // Ensure the current date starts at midnight this morning.
    var currentDate = moment().hour(0).minute(0).second(0);
    var valueDate;
    var coverStartDateStart = moment(options.globalModel.cover.coverStartDate).hour(0).minute(0).second(0);
    var coverStartDateEnd = moment(options.globalModel.cover.coverStartDate).hour(0).minute(0).second(0);
    var paymentFrequency = options.globalModel.payment.paymentFrequency;

    var REGEX = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
    if( REGEX.test(value) ) {
        result = true;
        valueDate = moment(value).hour(23).minute(59).second(59);
    }

    // Check if the date is actually a valid date.
    if( result ) {
        result = valueDate.isValid();
    }

    // Date is not in the past.
    if( result ) {
        result = valueDate.isAfter(currentDate.toDate());
        message = 'Date cannot be in the past';
    }

    // Cannot be a date before the Cover Start Date
    if( result ) {
        result = valueDate.isAfter(coverStartDateStart.toDate()); 
        message = 'Cannot be a date before the Cover Start Date';
    }

    // Date is not within 5 days from now.
    if (result) {
        result = valueDate.isAfter(moment().hour(0).minute(0).second(0).add(4, 'days').toDate());
        message = 'Date must be 5 days from now.';
    }

    // Date is not in beyond 2 months from now.
    if( result ) {
        if( paymentFrequency == 'Fortnightly' ) {
            coverStartDateEnd.add(2, 'weeks').add(1, 'day');
            message = 'Must be within 2 weeks of the cover start date.';
        }
        else {
            coverStartDateEnd.add(1, 'month').add(1, 'day');
            message = 'Must be within 1 month of the cover start date.';
        }
        result = ( valueDate.isBefore(coverStartDateEnd) );
    }

    if( result ) {
        message = '';
    }

    return {
        'result': result,
        'message': message,
        'details': []
    };
});